<template>
  <div class="account-register-container">
    <div class="account-register-bd">
      <div class="title"></div>
      <div v-if="!registerSuccess">
        <el-form
          :model="registerForm"
          size="small"
          label-width="120px"
          :rules="rules"
          ref="registerForm"
        >
          <el-form-item label="注册方式" prop="type">
            <el-select v-model="registerForm.type" class="w-280">
              <el-option value="username" label="用户名注册"></el-option>
              <el-option value="email" label="邮箱注册"></el-option>
              <el-option value="phone" label="手机号注册"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="用户名"
            prop="username"
            v-if="type === 'username'"
          >
            <el-input
              v-model="registerForm.username"
              placeholder="请输入用户名"
              class="w-280"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" v-if="type === 'username'">
            <el-input
              placeholder="请输入密码"
              v-model="registerForm.password"
              class="w-280"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="密码确认"
            prop="password_confirmation"
            v-if="type === 'username'"
          >
            <el-input
              placeholder="请再次输入密码"
              v-model="registerForm.password_confirmation"
              class="w-280"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email" v-if="type === 'email'">
            <el-input
              placeholder="请输入邮箱"
              prop="email"
              class="w-280"
              v-model="registerForm.email"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="验证码"
            prop="email_code"
            v-if="type === 'email'"
          >
            <el-input
              placeholder="请输入邮箱验证码"
              class="w-280"
              v-model="registerForm.email_code"
            >
              <el-button
                slot="append"
                @click="sendEmail"
                :disabled="sended || !emailPass"
                style="width: 130px"
                class="code-btn"
                >{{ emailBtnText }}</el-button
              >
            </el-input>
          </el-form-item>
          <el-link
            style="margin-left: 120px; cursor: default"
            :underline="false"
            type="success"
            v-if="sended && type === 'email'"
            ><i class="el-icon-info" style="margin-right: 4px"></i
            >激活邮件已发送至您的邮箱，请注意查收～</el-link
          >
          <el-form-item
            label="手机号"
            prop="phone_number"
            v-if="type === 'phone'"
          >
            <el-input
              label="请输入手机号"
              class="w-280"
              placeholder="请输入手机号"
              v-model="registerForm.phone_number"
            >
              <el-select
                slot="prepend"
                v-model="countryCode"
                style="width: 76px"
                class="country-code"
              >
                <el-option
                  v-for="(item, i) in geo"
                  :key="i"
                  :label="item.value"
                  :value="item.value"
                >
                  <span style="float: left; font-size: 13px">{{
                    item.name
                  }}</span>
                  <span
                    style="
                      float: right;
                      margin-left: 50px;
                      color: #8492a6;
                      font-size: 13px;
                    "
                    >{{ item.value }}</span
                  >
                </el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="手机验证码" prop="code" v-if="type === 'phone'">
            <el-input
              placeholder="请输入手机验证码"
              v-model="registerForm.code"
              class="w-280"
            >
              <el-button
                slot="append"
                style="width: 130px"
                @click="sendCode"
                :disabled="disabled"
                class="code-btn"
                >{{ btnText }}</el-button
              >
            </el-input>
          </el-form-item>
        </el-form>
        <el-button class="submit-btn" @click="submit" v-loading="submitLoading"
          >立即注册</el-button
        >
      </div>
      <div v-else>
        <div class="register-success-title">
          <img
            class="gou-icon"
            src="@/assets/images/gou.svg"
            alt=""
          />您已成功完成注册
        </div>

        <div class="register-success-tologin" @click="toLogin">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import geo from "@/utils/geo";
import authService from "@/global/service/auth";

export default {
  data() {
    const valiPwd = (rule, value, callback) => {
      if (value !== this.registerForm.password) {
        return callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    const valiEmail = (rule, value, callback) => {
      if (!this.emailReg.test(value)) {
        return callback(new Error("邮箱格式错误"));
      } else {
        this.emailPass = true;
        callback();
      }
    };
    return {
      registerForm: {
        type: "username",
        phone_number: "",
      },
      countryCode: "+86",
      emailPass: false,
      emailReg:
        /[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(?:\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/,
      geo: geo,
      sended: false,
      disabled: false,
      btnText: "发送验证码",
      emailBtnText: "发送验证码",
      type: "username",
      registerSuccess: false,
      submitLoading: false,
      rules: {
        type: {
          required: true,
          message: "请选择注册方式",
          trigger: "change",
        },
        username: {
          required: true,
          message: "用户名不能为空",
          trigger: "blur",
        },
        password: {
          required: true,
          message: "密码不能为空",
          trigger: "blur",
        },
        password_confirmation: [
          {
            validator: valiPwd,
            message: "两次输入密码不一致",
            trigger: "blur",
          },
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
        ],
        email: [
          {
            validator: valiEmail,
            message: "邮箱格式错误",
            trigger: "blur",
          },
          {
            required: true,
            message: "邮箱不能为空",
            trigger: "blur",
          },
        ],
        email_code: {
          required: true,
          message: "验证码不能为空",
          trigger: "blur",
        },
        phone_number: {
          required: true,
          message: "手机号不能为空",
          trigger: "blur",
        },
        code: {
          required: true,
          message: "验证码不能为空",
          trigger: "blur",
        },
      },
    };
  },
  watch: {
    "registerForm.type": {
      async handler(val) {
        if (val) {
          await this.$refs["registerForm"].clearValidate();
          this.type = val;
        }
      },
    },
  },
  created() {},
  methods: {
    sendEmail() {
      const email = this.registerForm.email;
      authService.emailSend({ email }).then(() => {
        this.sended = true;
        let time = 60;
        this.emailBtnText = `${time} 秒后重新发送`;
        const interval = setInterval(() => {
          time--;
          this.emailBtnText = `${time} 秒后重新发送`;
          if (time === 0) {
            clearInterval(interval);
            this.emailBtnText = "重新发送";
            this.sended = false;
          }
        }, 1000);
      });
    },
    sendCode() {
      if (!this.registerForm.phone_number.length) {
        return this.$message.error("请先输入手机号");
      }
      authService
        .phoneSms({
          phone_number: this.phoneForm.phone_number,
        })
        .then(() => {
          let time = 60;
          this.btnText = `${time} 秒后重新发送`;
          this.disabled = true;
          const interval = setInterval(() => {
            time--;
            this.btnText = `${time} 秒后重新发送`;
            if (time === 0) {
              clearInterval(interval);
              this.btnText = "重新发送";
              this.disabled = false;
            }
          }, 1000);
        });
    },
    submit() {
      this.$refs["registerForm"].validate((valid) => {
        if (valid) {
          let params = {};
          const obj = this.registerForm;
          switch (this.registerForm.type) {
            case "username":
              params = {
                username: obj.username,
                password: obj.password,
                password_confirmation: obj.password_confirmation,
                type: "username",
              };
              break;
            case "email":
              params = {
                email: obj.email,
                code: obj.email_code,
                type: "email",
              };
              break;
            case "phone":
              params = {
                phone_number: obj.phone_number,
                code: obj.code,
                type: "phone",
              };
              break;
          }
          this.submitLoading = true;
          authService
            .userRegister(params)
            .then(() => {
              this.registerSuccess = true;
            })
            .finally(() => {
              this.submitLoading = false;
            });
        }
      });
    },
    toLogin() {
      this.$router.push({
        name: "JkxyLogin",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.account-register-container {
  display: flex;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-position: center;
  padding-top: 80px;
  box-sizing: border-box;
  background: url("~@/assets/images/register-bg.gif");
  .account-register-bd {
    background: #fff;
    border: 1px solid #efefef;
    width: 800px;
    margin: 0 auto;
    padding: 60px 0 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    background-image: url("~@/assets/images/logo_login.png");
    width: 150px;
    height: 55px;
    display: inline-block;
    margin-bottom: 30px;
    background-size: 150px 55px;
    background-repeat: no-repeat;
  }
  .submit-btn {
    margin-top: 30px;
    background: #0fc700;
    color: #fff;
    border-color: #0fc700;
    transition: all 0.2s;
    margin-left: 120px;
    &:hover {
      opacity: 0.7;
    }
  }
  .code-btn {
    &.is-disabled {
      background-color: transparent !important;
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  .gou-icon {
    width: 32px;
    margin-right: 8px;
  }
  .register-success-title {
    display: flex;
    margin-bottom: 16px;
  }
  .register-success-tologin {
    font-size: 18px;
    color: #35b558;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
    font-family: PingFangSC-Semibold, PingFang SC;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
