export default [
  {
    name: "中国",
    value: "+86",
    icon: "flag-icon-cn",
  },
  {
    name: "中国香港",
    value: "+852",
    icon: "flag-icon-hk",
  },
  {
    name: "中国澳门",
    value: "+853",
    icon: "flag-icon-mo",
  },
  {
    name: "中国台湾",
    value: "+886",
    icon: "flag-icon-tw",
  },
  {
    name: "安道尔共和国",
    value: "+376",
    icon: "flag-icon-ad",
  },
  {
    name: "阿拉伯联合酋长国",
    value: "+971",
    icon: "flag-icon-ae",
  },
  {
    name: "阿富汗",
    value: "+93",
    icon: "flag-icon-af",
  },
  {
    name: "安提瓜和巴布达",
    value: "+1268",
    icon: "flag-icon-ag",
  },
  {
    name: "安圭拉岛",
    value: "+1264",
    icon: "flag-icon-ai",
  },
  {
    name: "阿尔巴尼亚",
    value: "+355",
    icon: "flag-icon-al",
  },
  {
    name: "亚美尼亚",
    value: "+374",
    icon: "flag-icon-am",
  },
  {
    name: "阿森松",
    value: "+247",
    icon: "flag-icon-sh",
  },
  {
    name: "安哥拉",
    value: "+244",
    icon: "flag-icon-ao",
  },
  {
    name: "阿根廷",
    value: "+54",
    icon: "flag-icon-ar",
  },
  {
    name: "奥地利",
    value: "+43",
    icon: "flag-icon-at",
  },
  {
    name: "澳大利亚",
    value: "+61",
    icon: "flag-icon-au",
  },
  {
    name: "阿塞拜疆",
    value: "+994",
    icon: "flag-icon-az",
  },
  {
    name: "巴巴多斯",
    value: "+1246",
    icon: "flag-icon-bb",
  },
  {
    name: "孟加拉国",
    value: "+880",
    icon: "flag-icon-bd",
  },
  {
    name: "比利时",
    value: "+32",
    icon: "flag-icon-be",
  },
  {
    name: "布基纳法索",
    value: "+faso",
    icon: "flag-icon-bf",
  },
  {
    name: "保加利亚",
    value: "+359",
    icon: "flag-icon-bg",
  },
  {
    name: "巴林",
    value: "+973",
    icon: "flag-icon-bh",
  },
  {
    name: "布隆迪",
    value: "+257",
    icon: "flag-icon-bi",
  },
  {
    name: "贝宁",
    value: "+229",
    icon: "flag-icon-bj",
  },
  {
    name: "巴勒斯坦",
    value: "+970",
    icon: "flag-icon-bl",
  },
  {
    name: "百慕大群岛",
    value: "+1441",
    icon: "flag-icon-bm",
  },
  {
    name: "文莱",
    value: "+673",
    icon: "flag-icon-bn",
  },
  {
    name: "玻利维亚",
    value: "+591",
    icon: "flag-icon-bo",
  },
  {
    name: "巴西",
    value: "+55",
    icon: "flag-icon-br",
  },
  {
    name: "巴哈马",
    value: "+1242",
    icon: "flag-icon-bs",
  },
  {
    name: "博茨瓦纳",
    value: "+267",
    icon: "flag-icon-bw",
  },
  {
    name: "白俄罗斯",
    value: "+375",
    icon: "flag-icon-by",
  },
  {
    name: "伯利兹",
    value: "+501",
    icon: "flag-icon-bz",
  },
  {
    name: "加拿大",
    value: "+1",
    icon: "flag-icon-ca",
  },
  {
    name: "开曼群岛",
    value: "+1345",
    icon: "flag-icon-ky",
  },
  {
    name: "中非共和国",
    value: "+236",
    icon: "flag-icon-cf",
  },
  {
    name: "刚果",
    value: "+242",
    icon: "flag-icon-cg",
  },
  {
    name: "瑞士",
    value: "+41",
    icon: "flag-icon-ch",
  },
  {
    name: "库克群岛",
    value: "+682",
    icon: "flag-icon-ck",
  },
  {
    name: "智利",
    value: "+56",
    icon: "flag-icon-cl",
  },
  {
    name: "喀麦隆",
    value: "+237",
    icon: "flag-icon-cm",
  },
  {
    name: "哥伦比亚",
    value: "+57",
    icon: "flag-icon-co",
  },
  {
    name: "哥斯达黎加",
    value: "+506",
    icon: "flag-icon-cr",
  },
  {
    name: "古巴",
    value: "+53",
    icon: "flag-icon-cu",
  },
  {
    name: "塞浦路斯",
    value: "+357",
    icon: "flag-icon-cy",
  },
  {
    name: "捷克",
    value: "+420",
    icon: "flag-icon-cz",
  },
  {
    name: "德国",
    value: "+49",
    icon: "flag-icon-de",
  },
  {
    name: "吉布提",
    value: "+253",
    icon: "flag-icon-dj",
  },
  {
    name: "丹麦",
    value: "+45",
    icon: "flag-icon-dk",
  },
  {
    name: "多米尼加共和国",
    value: "+1890",
    icon: "flag-icon-do",
  },
  {
    name: "阿尔及利亚",
    value: "+213",
    icon: "flag-icon-dz",
  },
  {
    name: "厄瓜多尔",
    value: "+593",
    icon: "flag-icon-ec",
  },
  {
    name: "爱沙尼亚",
    value: "+372",
    icon: "flag-icon-ee",
  },
  {
    name: "埃及",
    value: "+20",
    icon: "flag-icon-eg",
  },
  {
    name: "西班牙",
    value: "+34",
    icon: "flag-icon-es",
  },
  {
    name: "埃塞俄比亚",
    value: "+251",
    icon: "flag-icon-et",
  },
  {
    name: "芬兰",
    value: "+358",
    icon: "flag-icon-fi",
  },
  {
    name: "斐济",
    value: "+679",
    icon: "flag-icon-fj",
  },
  {
    name: "法国",
    value: "+33",
    icon: "flag-icon-fr",
  },
  {
    name: "加蓬",
    value: "+241",
    icon: "flag-icon-ga",
  },
  {
    name: "英国",
    value: "+44",
    icon: "flag-icon-gb",
  },
  {
    name: "格林纳达",
    value: "+1809",
    icon: "flag-icon-gd",
  },
  {
    name: "格鲁吉亚",
    value: "+995",
    icon: "flag-icon-ge",
  },
  {
    name: "法属圭亚那",
    value: "+594",
    icon: "flag-icon-gf",
  },
  {
    name: "加纳",
    value: "+233",
    icon: "flag-icon-gh",
  },
  {
    name: "直布罗陀",
    value: "+350",
    icon: "flag-icon-gi",
  },
  {
    name: "冈比亚",
    value: "+220",
    icon: "flag-icon-gm",
  },
  {
    name: "几内亚",
    value: "+224",
    icon: "flag-icon-gn",
  },
  {
    name: "希腊",
    value: "+30",
    icon: "flag-icon-gr",
  },
  {
    name: "危地马拉",
    value: "+502",
    icon: "flag-icon-gt",
  },
  {
    name: "关岛",
    value: "+1671",
    icon: "flag-icon-gu",
  },
  {
    name: "圭亚那",
    value: "+592",
    icon: "flag-icon-gy",
  },
  {
    name: "洪都拉斯",
    value: "+504",
    icon: "flag-icon-hn",
  },
  {
    name: "海地",
    value: "+509",
    icon: "flag-icon-ht",
  },
  {
    name: "匈牙利",
    value: "+36",
    icon: "flag-icon-hu",
  },
  {
    name: "印度尼西亚",
    value: "+62",
    icon: "flag-icon-id",
  },
  {
    name: "爱尔兰",
    value: "+353",
    icon: "flag-icon-ie",
  },
  {
    name: "以色列",
    value: "+972",
    icon: "flag-icon-il",
  },
  {
    name: "印度",
    value: "+91",
    icon: "flag-icon-in",
  },
  {
    name: "伊拉克",
    value: "+964",
    icon: "flag-icon-iq",
  },
  {
    name: "伊朗",
    value: "+98",
    icon: "flag-icon-ir",
  },
  {
    name: "冰岛",
    value: "+354",
    icon: "flag-icon-is",
  },
  {
    name: "意大利",
    value: "+39",
    icon: "flag-icon-it",
  },
  {
    name: "牙买加",
    value: "+1876",
    icon: "flag-icon-jm",
  },
  {
    name: "约旦",
    value: "+962",
    icon: "flag-icon-jo",
  },
  {
    name: "日本",
    value: "+81",
    icon: "flag-icon-jp",
  },
  {
    name: "肯尼亚",
    value: "+254",
    icon: "flag-icon-ke",
  },
  {
    name: "吉尔吉斯坦",
    value: "+331",
    icon: "flag-icon-kg",
  },
  {
    name: "柬埔寨",
    value: "+855",
    icon: "flag-icon-kh",
  },
  {
    name: "朝鲜",
    value: "+850",
    icon: "flag-icon-kp",
  },
  {
    name: "韩国",
    value: "+82",
    icon: "flag-icon-kr",
  },
  {
    name: "科威特",
    value: "+965",
    icon: "flag-icon-kw",
  },
  {
    name: "哈萨克斯坦",
    value: "+327",
    icon: "flag-icon-kz",
  },
  {
    name: "老挝",
    value: "+856",
    icon: "flag-icon-la",
  },
  {
    name: "黎巴嫩",
    value: "+961",
    icon: "flag-icon-lb",
  },
  {
    name: "圣卢西亚",
    value: "+1758",
    icon: "flag-icon-lc",
  },
  {
    name: "列支敦士登",
    value: "+423",
    icon: "flag-icon-li",
  },
  {
    name: "斯里兰卡",
    value: "+94",
    icon: "flag-icon-lk",
  },
  {
    name: "利比里亚",
    value: "+231",
    icon: "flag-icon-lr",
  },
  {
    name: "莱索托",
    value: "+266",
    icon: "flag-icon-ls",
  },
  {
    name: "立陶宛",
    value: "+370",
    icon: "flag-icon-lt",
  },
  {
    name: "卢森堡",
    value: "+352",
    icon: "flag-icon-lu",
  },
  {
    name: "拉脱维亚",
    value: "+371",
    icon: "flag-icon-lv",
  },
  {
    name: "利比亚",
    value: "+218",
    icon: "flag-icon-ly",
  },
  {
    name: "摩洛哥",
    value: "+212",
    icon: "flag-icon-ma",
  },
  {
    name: "摩纳哥",
    value: "+377",
    icon: "flag-icon-mc",
  },
  {
    name: "摩尔多瓦",
    value: "+373",
    icon: "flag-icon-md",
  },
  {
    name: "马达加斯加",
    value: "+261",
    icon: "flag-icon-mg",
  },
  {
    name: "马里",
    value: "+223",
    icon: "flag-icon-ml",
  },
  {
    name: "缅甸",
    value: "+95",
    icon: "flag-icon-mmo",
  },
  {
    name: "蒙古",
    value: "+976",
    icon: "flag-icon-mn",
  },
  {
    name: "蒙特塞拉特岛",
    value: "+1664",
    icon: "flag-icon-ms",
  },
  {
    name: "马耳他",
    value: "+356",
    icon: "flag-icon-mt",
  },
  {
    name: "马里亚那群岛",
    value: "+1670",
    icon: "flag-icon-mp",
  },
  {
    name: "马提尼克",
    value: "+596",
    icon: "flag-icon-mq",
  },
  {
    name: "毛里求斯",
    value: "+230",
    icon: "flag-icon-mu",
  },
  {
    name: "马尔代夫",
    value: "+960",
    icon: "flag-icon-mv",
  },
  {
    name: "马拉维",
    value: "+265",
    icon: "flag-icon-mw",
  },
  {
    name: "墨西哥",
    value: "+52",
    icon: "flag-icon-mx",
  },
  {
    name: "马来西亚",
    value: "+60",
    icon: "flag-icon-my",
  },
  {
    name: "莫桑比克",
    value: "+258",
    icon: "flag-icon-mz",
  },
  {
    name: "纳米比亚",
    value: "+264",
    icon: "flag-icon-na",
  },
  {
    name: "尼日尔",
    value: "+977",
    icon: "flag-icon-ne",
  },
  {
    name: "尼日利亚",
    value: "+234",
    icon: "flag-icon-ng",
  },
  {
    name: "尼加拉瓜",
    value: "+505",
    icon: "flag-icon-ni",
  },
  {
    name: "荷兰",
    value: "+31",
    icon: "flag-icon-nl",
  },
  {
    name: "挪威",
    value: "+47",
    icon: "flag-icon-no",
  },
  {
    name: "尼泊尔",
    value: "+977",
    icon: "flag-icon-np",
  },
  {
    name: "瑙鲁",
    value: "+674",
    icon: "flag-icon-nr",
  },
  {
    name: "新西兰",
    value: "+64",
    icon: "flag-icon-nz",
  },
  {
    name: "阿曼",
    value: "+968",
    icon: "flag-icon-om",
  },
  {
    name: "巴拿马",
    value: "+507",
    icon: "flag-icon-pa",
  },
  {
    name: "秘鲁",
    value: "+51",
    icon: "flag-icon-pe",
  },
  {
    name: "法属玻利尼西亚",
    value: "+689",
    icon: "flag-icon-pf",
  },
  {
    name: "巴布亚新几内亚",
    value: "+675",
    icon: "flag-icon-pg",
  },
  {
    name: "菲律宾",
    value: "+63",
    icon: "flag-icon-ph",
  },
  {
    name: "巴基斯坦",
    value: "+92",
    icon: "flag-icon-pk",
  },
  {
    name: "波兰",
    value: "+48",
    icon: "flag-icon-pl",
  },
  {
    name: "波多黎各",
    value: "+1787",
    icon: "flag-icon-pr",
  },
  {
    name: "葡萄牙",
    value: "+351",
    icon: "flag-icon-pt",
  },
  {
    name: "巴拉圭",
    value: "+595",
    icon: "flag-icon-py",
  },
  {
    name: "卡塔尔",
    value: "+974",
    icon: "flag-icon-qa",
  },
  {
    name: "留尼旺",
    value: "+262",
    icon: "flag-icon-re",
  },
  {
    name: "罗马尼亚",
    value: "+40",
    icon: "flag-icon-ro",
  },
  {
    name: "俄罗斯",
    value: "+7",
    icon: "flag-icon-ru",
  },
  {
    name: "沙特阿拉伯",
    value: "+966",
    icon: "flag-icon-sa",
  },
  {
    name: "所罗门群岛",
    value: "+677",
    icon: "flag-icon-sb",
  },
  {
    name: "塞舌尔",
    value: "+248",
    icon: "flag-icon-sc",
  },
  {
    name: "苏丹",
    value: "+249",
    icon: "flag-icon-sd",
  },
  {
    name: "瑞典",
    value: "+46",
    icon: "flag-icon-se",
  },
  {
    name: "新加坡",
    value: "+65",
    icon: "flag-icon-sg",
  },
  {
    name: "斯洛文尼亚",
    value: "+386",
    icon: "flag-icon-si",
  },
  {
    name: "斯洛伐克",
    value: "+421",
    icon: "flag-icon-sk",
  },
  {
    name: "塞拉利昂",
    value: "+232",
    icon: "flag-icon-sl",
  },
  {
    name: "圣马力诺",
    value: "+378",
    icon: "flag-icon-sm",
  },
  {
    name: "东萨摩亚",
    value: "+684",
    icon: "flag-icon-as",
  },
  {
    name: "西萨摩亚",
    value: "+685",
    icon: "flag-icon-sm",
  },
  {
    name: "塞内加尔",
    value: "+221",
    icon: "flag-icon-sn",
  },
  {
    name: "索马里",
    value: "+252",
    icon: "flag-icon-so",
  },
  {
    name: "苏里南",
    value: "+597",
    icon: "flag-icon-sr",
  },
  {
    name: "圣多美和普林西比",
    value: "+239",
    icon: "flag-icon-st",
  },
  {
    name: "萨尔瓦多",
    value: "+503",
    icon: "flag-icon-sv",
  },
  {
    name: "叙利亚",
    value: "+963",
    icon: "flag-icon-sy",
  },
  {
    name: "斯威士兰",
    value: "+268",
    icon: "flag-icon-sz",
  },
  {
    name: "乍得",
    value: "+235",
    icon: "flag-icon-td",
  },
  {
    name: "多哥",
    value: "+228",
    icon: "flag-icon-tg",
  },
  {
    name: "泰国",
    value: "+66",
    icon: "flag-icon-th",
  },
  {
    name: "塔吉克斯坦",
    value: "+992",
    icon: "flag-icon-tj",
  },
  {
    name: "土库曼斯坦",
    value: "+993",
    icon: "flag-icon-tm",
  },
  {
    name: "突尼斯",
    value: "+216",
    icon: "flag-icon-tn",
  },
  {
    name: "汤加",
    value: "+676",
    icon: "flag-icon-to",
  },
  {
    name: "土耳其",
    value: "+90",
    icon: "flag-icon-tr",
  },
  {
    name: "特立尼达和多巴哥",
    value: "+1809",
    icon: "flag-icon-tt",
  },
  {
    name: "坦桑尼亚",
    value: "+255",
    icon: "flag-icon-tz",
  },
  {
    name: "乌克兰",
    value: "+380",
    icon: "flag-icon-ua",
  },
  {
    name: "乌干达",
    value: "+256",
    icon: "flag-icon-ug",
  },
  {
    name: "美国",
    value: "+1",
    icon: "flag-icon-us",
  },
  {
    name: "乌拉圭",
    value: "+598",
    icon: "flag-icon-uy",
  },
  {
    name: "乌兹别克斯坦",
    value: "+233",
    icon: "flag-icon-uz",
  },
  {
    name: "圣文森特岛",
    value: "+1784",
    icon: "flag-icon-vc",
  },
  {
    name: "委内瑞拉",
    value: "+58",
    icon: "flag-icon-ve",
  },
  {
    name: "越南",
    value: "+84",
    icon: "flag-icon-vn",
  },
  {
    name: "也门",
    value: "+967",
    icon: "flag-icon-ye",
  },
  {
    name: "南非",
    value: "+27",
    icon: "flag-icon-za",
  },
  {
    name: "赞比亚",
    value: "+260",
    icon: "flag-icon-zm",
  },
  {
    name: "津巴布韦",
    value: "+263",
    icon: "flag-icon-zw",
  },
];
